const scripts: Record<string, Promise<void>> = {};

function createScriptTag(
  src: string,
  async: boolean,
  resolve: () => void,
  reject: (reason: string) => void,
) {
  const script = document.createElement("script");
  script.src = src;
  script.onload = () => resolve();
  script.onerror = () => reject(`Error loading script ${src}`);
  script.async = async;

  document.head.appendChild(script);
}

export default function loadScript(src: string, async = true): Promise<void> {
  if (!scripts[src]) {
    scripts[src] = new Promise((resolve, reject) => {
      createScriptTag(src, async, resolve, reject);
    });
  }
  return scripts[src];
}
