const FALLBACK_LOCALE = "int-en";

export default {
  available: [
    "ar-es",
    "at-de",
    "au-en",
    "az-az",
    "ba-bs",
    "be-fr",
    "be-nl",
    "bo-es",
    "br-pt",
    "ca-en",
    "ca-fr",
    "car-en",
    "car-es",
    "ch-de",
    "ch-fr",
    "cl-es",
    "cn-zh",
    "co-es",
    "cz-cs",
    "de-de",
    "dk-da",
    "ee-et",
    "es-es",
    "fi-fi",
    "fr-fr",
    "gb-en",
    "glo-bl",
    "gr-el",
    "hk-zh",
    "hr-hr",
    "hu-hu",
    "ie-en",
    "in-en",
    "int-en",
    "it-it",
    "jp-ja",
    "kr-ko",
    "kz-ru",
    "lt-lt",
    "lv-lv",
    "mea-ar",
    "mea-en",
    "mx-es",
    "my-en",
    "nl-nl",
    "no-no",
    "nz-en",
    "pe-es",
    "pl-pl",
    "pt-pt",
    "ro-ro",
    "rs-sr",
    "ru-ru",
    "se-en",
    "sg-en",
    "si-sl",
    "sk-sk",
    "th-th",
    "tr-tr",
    "tw-zh",
    "ua-uk",
    "us-en",
    "za-en",
    "kw-en",
    "kw-ar",
    "mk-mk",
    "ae-ar",
    "ae-en",
    "al-sq",
    "dz-ar",
    "dz-fr",
    "ao-pt",
    "bh-ar",
    "bh-en",
    "bg-bg",
    "cr-es",
    "do-es",
    "ge-ka",
    "eg-ar",
    "eg-en",
    "is-en",
    "il-he",
    "jo-ar",
    "jm-en",
    "kv-sq",
    "lb-ar",
    "mv-en",
    "mt-en",
    "om-ar",
    "om-en",
    "pk-en",
    "pa-es",
    "ph-en",
    "qa-ar",
    "qa-en",
    "lk-en",
  ],
  fallback: FALLBACK_LOCALE,
};
