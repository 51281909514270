/**
 * Analytics click events want the full URL sent as a click event
 * Often we only have a relative URL (`/example` vs `https://redbull.com/example`)
 * This helper works out if the current string looks like a relative URL,
 * and appends the current protocol+hostname to it.
 */

/**
 * Breakdown of the Regex (thanks ChatGPT), used in this file:
 *
 * `^`
 * This asserts the start of the string. It ensures that the pattern we're looking for appears at the beginning of the URL string.

 * `[a-zA-Z]`
 * This matches a single alphabetic character (either uppercase or lowercase). This ensures that the URL starts with a letter, which is a requirement for the URL scheme (e.g., http, https, ftp, etc.).
 *
 * `[a-zA-Z\d+\-.]*`
 * This matches zero or more characters that can be:
 * Letters (a-zA-Z)
 * Digits (\d, equivalent to 0-9)
 * Plus signs (+)
 * Hyphens (-)
 * Periods (.)
 * This sequence corresponds to the rest of the URL scheme after the first letter. For example, in https, this part would match ttps.
 *
 * `:``
 * This matches a literal colon (:). A colon is required to indicate the end of the scheme and the beginning of the rest of the URL (like https://, ftp://, etc.).
 *
 * test(url):
 * This method tests whether the given string url matches the pattern defined by the regex. It returns true if it matches and false otherwise.
 * 
 * What This Regex Checks:
 * The regex checks if the URL starts with a valid URL scheme followed by a colon. For instance, it matches URLs like:
 * `http://`
 * `https://`
 * `ftp://`
 * `mailto:`
 * `file://`
 * These schemes are the first part of an absolute URL, which tells the browser how to handle the resource.
 *
 * Example of Matching:
 * `https://www.example.com`:
 * Matches because https: is a valid URL scheme.
 * `ftp://fileserver.com`:
 * Matches because ftp: is a valid URL scheme.
 * `mailto:someone@example.com`:
 * Matches because mailto: is a valid URL scheme.
 * `/example/path`:
 * Does not match because it starts with a /, not a scheme.
 */

export const appendHostnameToRelativeUrl = (url: string): string => {
  if (typeof url !== "string" || url.trim() === "") {
    return url; // Return the input unmodified if it's not a string or is a blank string
  }

  if (typeof window === "undefined") {
    // If we're on the server side, return the URL unmodified since `window` is not available
    return url;
  }

  const { hostname, protocol } = window.location;

  if (url.startsWith("#")) {
    // If the URL is an ID fragment identifier, prepend the current URL onto it
    return `${protocol}//${hostname}${url}`;
  }

  if (url.startsWith("/") && !url.startsWith("//")) {
    // If the URL is a relative URL (starts with a single '/'), prepend the hostname to it
    return `${protocol}//${hostname}${url}`;
  }

  /* See detailed regex explantion above */
  if (/^[a-zA-Z][a-zA-Z\d+\-.]*:/.test(url)) {
    // If the URL is an absolute URL (starting with `https://` or `mailto:`, return it unmodified
    return url;
  }

  // TODO: investigate refactor of # / and https lines if we update to a later version of Node:
  // See comments: https://github.com/redbullmediahouse/rb-pcs/pull/1696

  /*
  return URL.canParse(url)
    ? url // `URL.canParse(url)` only returns `true` if `url` is already fully-qualified.
    : new URL(url, window.location.origin).toString(); // Otherwise you can use the URL.toString() to handle all the slash de-duplication etc.
  */

  return url; // For any other case, return the URL unmodified
};
