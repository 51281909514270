import { assertNotNullish } from "./assertNotNullish";
import { createContext, useContext } from "react";

export type TextDirection = "ltr" | "rtl";

export const TextDirectionContext = createContext<TextDirection | undefined>(
  undefined,
);

export const useTextDirectionContext = (): TextDirection => {
  const context = useContext(TextDirectionContext);

  assertNotNullish(
    context,
    "useTextDirectionContext was used outside of Provider",
  );

  return context;
};
