import { assertNotNullish } from "./assertNotNullish";
import { createContext, useContext } from "react";

export const LocaleStringContext = createContext<string | undefined>(undefined);

export const useLocaleStringContext = (): string => {
  const context = useContext(LocaleStringContext);

  assertNotNullish(
    context,
    "useLocaleStringContext was used outside of Provider",
  );

  return context;
};
