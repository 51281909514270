/**
 * This abstraction exists because this application supports server-side
 * rendering, where the `window` global does not exist. This `dataLayer` object
 * will not need a truthiness check, and it will _always_ be a not-undefined
 * array, on both browser-side and server-side.
 */

const dataLayer: unknown[] =
  (typeof window !== "undefined" && window.dataLayer) || [];

if (typeof window !== "undefined") {
  window.dataLayer = dataLayer;
}

export { dataLayer };
