import { createEnv } from "@t3-oss/env-nextjs";
import { z } from "zod";

export const env = createEnv({
  /*
   * Serverside Environment variables, not available on the client.
   * Will throw if you access these variables on the client.
   */
  server: {
    CONTENTFUL_ENDPOINT: z.string().nonempty(),
    CONTENTFUL_ACCESS_TOKEN: z.string().nonempty(),
    CONTENTFUL_PREVIEW: z.boolean({
      description:
        "By default, Contentful queries do not return draft content. If you would like to override this behaviour, enable this flag. Needs to be used in conjunction with a CONTENTFUL_ACCESS_TOKEN with preview access.",
    }),
    CONTENTFUL_TESTING_LOCALES_ENABLED: z.boolean({
      description:
        "Feature flag which determines whether or not we should show testing locales like `ne-xt` or `pl-ay`.",
    }),
    CANONICAL_URL_HOST: z.string().nonempty().default("www.redbull.com"),
    HEROKU_SLUG_COMMIT: z
      .string({
        description: `This comes from “Dyno Metadata” on Heroku, see: https://devcenter.heroku.com/articles/dyno-metadata. It's not needed outside the context of Heroku, which is why it's marked as .optional()`,
      })
      .optional(),
    ZENDESK_USERNAME: z.string().nonempty(),
    ZENDESK_PASSWORD: z.string().nonempty(),
    ZENDESK_ENDPOINT: z.string().nonempty(),
    GOOGLE_RECAPTCHA_ENDPOINT: z.string().nonempty(),
    GOOGLE_RECAPTCHA_SECRET_KEY: z.string().nonempty(),
    CREPO_GRAPHQL_ENDPOINT: z.string().nonempty(),
    CREPO_GRAPHQL_API_KEY: z.string().nonempty(),
    STORYBLOK_PREVIEW_ACCESS_TOKEN: z
      .string({
        description:
          "Retrieved from: https://app.storyblok.com/#/me/spaces/287059/settings?tab=api",
      })
      .nonempty(),
    STORYBLOK_FAQS_ENABLED: z.string().nullish(),
  },
  /*
   * Environment variables available on the client (and server).
   *
   * 💡 You'll get type errors if these are not prefixed with NEXT_PUBLIC_.
   */
  client: {
    NEXT_PUBLIC_HAS_GTM_CONTAINER: z.boolean({
      description:
        "This should be “true” in environments where GTM should be enabled (ie. non-development environments)",
    }),
    NEXT_PUBLIC_ASSET_PREFIX: z.string({
      description:
        "Has to be used as `assetPrefix` in next.config.js because this application does not live at the domain root in production. As such, we also need to prefix all asset paths with it.",
    }),
  },
  /*
   * Due to how Next.js bundles environment variables on Edge and Client,
   * we need to manually destructure them to make sure all are included in bundle.
   *
   * 💡 You'll get type errors if not all variables from `server` & `client` are included here.
   */
  runtimeEnv: {
    STORYBLOK_FAQS_ENABLED: process.env.STORYBLOK_FAQS_ENABLED,
    NEXT_PUBLIC_HAS_GTM_CONTAINER:
      process.env.NEXT_PUBLIC_HAS_GTM_CONTAINER === "true",
    CONTENTFUL_ENDPOINT: process.env.CONTENTFUL_ENDPOINT,
    CONTENTFUL_ACCESS_TOKEN: process.env.CONTENTFUL_ACCESS_TOKEN,
    CONTENTFUL_PREVIEW: process.env.CONTENTFUL_PREVIEW === "true",
    CONTENTFUL_TESTING_LOCALES_ENABLED:
      process.env.CONTENTFUL_TESTING_LOCALES_ENABLED === "true",
    CANONICAL_URL_HOST: process.env.CANONICAL_URL_HOST,
    HEROKU_SLUG_COMMIT: process.env.HEROKU_SLUG_COMMIT,
    ZENDESK_USERNAME: process.env.ZENDESK_USERNAME,
    ZENDESK_PASSWORD: process.env.ZENDESK_PASSWORD,
    ZENDESK_ENDPOINT: process.env.ZENDESK_ENDPOINT,
    GOOGLE_RECAPTCHA_ENDPOINT: process.env.GOOGLE_RECAPTCHA_ENDPOINT,
    GOOGLE_RECAPTCHA_SECRET_KEY: process.env.GOOGLE_RECAPTCHA_SECRET_KEY,
    NEXT_PUBLIC_ASSET_PREFIX: process.env.NEXT_PUBLIC_ASSET_PREFIX,
    CREPO_GRAPHQL_ENDPOINT: process.env.CREPO_GRAPHQL_ENDPOINT,
    CREPO_GRAPHQL_API_KEY: process.env.CREPO_GRAPHQL_API_KEY,
    STORYBLOK_PREVIEW_ACCESS_TOKEN: process.env.STORYBLOK_PREVIEW_ACCESS_TOKEN,
  },
});
