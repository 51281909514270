import { KickerKind } from "../components/kicker/kicker";
import { assertNotNullish } from "./assertNotNullish";
import { createContext, useContext } from "react";

export const KickerContext = createContext<KickerKind | undefined>(undefined);

export const useKickerContext = () => {
  const context = useContext(KickerContext);

  assertNotNullish(context, "useKickerContext was used outside of Provider");

  return context;
};
